/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.12 public/models/v3.glb
*/

import React, { useEffect, useRef } from 'react'
import { OrbitControls, PresentationControls, useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'

export default function V18_8({ handleModelBoundingBox, ...props }) {
  const { nodes, materials } = useGLTF('./models/v18_8.glb')
  let groupRef = useRef(null)
  let meshRef = useRef(null)

  useFrame(() => {
    if (groupRef?.current) {
      groupRef.current.rotation.y = groupRef.current.rotation.y - 0.025
      // groupRef.current.rotation.y -= 0.005
      // const boundingBox = new THREE.Box3().setFromObject(groupRef.current)
      // console.log(boundingBox)
    }
  })

  // useEffect(() => {
  //   const boundingBox = new THREE.Box3().setFromObject(groupRef.current)
  //   console.log(boundingBox)
  //   handleModelBoundingBox(boundingBox)
  // }, [groupRef])

  return (
    // <PresentationControls global>
    <group
      {...props}
      dispose={null}
      ref={groupRef}
    >
      <mesh
        ref={meshRef}
        castShadow
        // receiveShadow
        geometry={nodes['outer-spiral'].geometry}
        // material={nodes['outer-spiral'].material}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <meshPhysicalMaterial
          color={'#808080'}
          // roughness={0.1}
          clearcoat={1}
          clearcoatRoughness={0.1}
          metalness={0.9}
          roughness={0.5}
        />
        <meshStandardMaterial
          fog={false}
          transparent
          // color="#808080"
          color="#808080"
          // color="#000"
          roughness={0.1}
          // wireframe
          // castShadow
        />
      </mesh>
    </group>
    // </PresentationControls>
  )
}

useGLTF.preload('./models/v18_8.glb')
