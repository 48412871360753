/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 macbook-pro-HW.glb 
*/

import React, { forwardRef, useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { MathUtils } from 'three'

const MacbookProHW = (props, ref) => {
  const { nodes, materials } = useGLTF('/models/macbook-pro-HW.glb')
  return (
    <group
      ref={ref}
      {...props}
      dispose={null}
    >
      <mesh
        geometry={nodes.Keyboard.geometry}
        material={materials.Frame}
      >
        <meshPhysicalMaterial
          color={'#808080'}
          // roughness={0.1}
          clearcoat={1}
          clearcoatRoughness={0.1}
          metalness={0.9}
          roughness={0.5}
        />
        <mesh
          geometry={nodes.Body.geometry}
          material={materials.Frame}
        >
          <meshPhysicalMaterial
            color={'#c0c0c0'}
            // roughness={0.1}
            clearcoat={1}
            clearcoatRoughness={0.1}
            metalness={0.9}
            roughness={0.5}
          />
        </mesh>
      </mesh>
      <mesh
        geometry={nodes.Frame.geometry}
        material={materials.Frame}
        position={[0, -0.62, 0.047]}
        rotation={[MathUtils.degToRad(90), 0, 0]}
      >
        <meshPhysicalMaterial
          color={'#505050'}
          // roughness={0.1}
          clearcoat={1}
          clearcoatRoughness={0.1}
          // metalness={0.9}
          roughness={0}
        />

        <mesh
          geometry={nodes.Logo.geometry}
          material={materials.Logo}
          position={[0, 1.2, -0.106]}
        />
        <mesh
          geometry={nodes.Screen.geometry}
          material={materials.Screen}
          position={[0, 1.2, -0.106]}
        >
          <meshBasicMaterial color={'black'} />
          {props.children}
        </mesh>
      </mesh>
    </group>
  )
}

useGLTF.preload('/models/macbook-pro-HW.glb')

export default forwardRef(MacbookProHW)
