/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 ./public/iphone-11.glb 
*/

import { forwardRef, useMemo, useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { MeshStandardMaterial, TextureLoader } from 'three'

const IPhone11Model = (props, ref) => {
  const { nodes, materials } = useGLTF('/iphone-11.glb')

  const dummySS = useMemo(() => {
    let temp = new TextureLoader().load('./dummy-phone-ss.jpg')
    temp.flipY = false
    return temp
  }, [])

  return (
    <group
      {...props}
      dispose={null}
      scale={0.25}
      ref={ref}
    >
      <mesh
        geometry={nodes.Frame.geometry}
        // material={materials.Frame}
        rotation={[0, -Math.PI / 2, 0]}
      >
        <meshPhysicalMaterial
          color={'#343b53'}
          // roughness={0.1}
          clearcoat={1}
          clearcoatRoughness={0.1}
          metalness={0.9}
          roughness={0.5}
        />
      </mesh>
      <mesh
        geometry={nodes.Logo.geometry}
        material={materials.Logo}
      />
      <mesh
        geometry={nodes.Screen.geometry}
        // material={materials.Screen}
        rotation={[0, -Math.PI / 2, 0]}
      >
        <meshPhysicalMaterial
          clearcoat={0}
          clearcoatRoughness={0}
          metalness={0}
          roughness={0}
          map={dummySS}
        />
      </mesh>
    </group>
  )
}

useGLTF.preload('/iphone-11.glb')

export default forwardRef(IPhone11Model)
